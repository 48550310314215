* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

html{
    // font-size: 10px;   
    font-size: calc(100vw / 1440 * 10);
    
    @include media('<=phone'){
        font-size: calc(100vw / 425 * 10);
        // font-size: 10px
    }
}

body {
    @extend %text-md;
    font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a {
    text-decoration: none;
    color: white;
    cursor: pointer;

}

a button {
    cursor: pointer;
}